@import "rsuite/dist/rsuite.css";
:root{
  --primary: #FF4D49;
  --rs-btn-link-text: #FF4D49;
  --rs-calendar-table-cell-content: #FF4D49;
  --rs-input-focus-border: #FF4D49;
  --rs-bg-active: #FF4D49;
  --rs-calendar-table-cell-day: #FF4D49;
  --rs-listbox-option-hover-text: #FF4D49;
  --rs-calendar-table-cell: #FF4D49;
  --rs-calendar-table-cell-in-rang: #FF4D49;
  --rs-listbox-option-hover-bg: rgba(255,77,73, 0.4);
  --rs-calendar-table-cell: #FF4D49;
  --rs-calendar-table-cell-in-range: #FF4D49;
  --rs-primary: #FF4D49;
  --rs-picker-value: #FF4D49;
  --rs-btn-primary-bg: #FF4D49;
  --rs-btn-primary-hover-bg: #FF4D49;
  --rs-calendar-table-cell-content: #FF4D49 !important;
  --rs-btn-link-hover-text: #FF4D49;
  --rs-calendar-range-bg: rgba(255,77,73, 0.3);
  /* --rs-calendar-table-cell-selected: #FF4D49; */
  /* rs-calendar-table-cell rs-calendar-table-cell-in-range */
}
