.timesheet {
  .selected {
    background-color: #007bff;
  }

  .timesheet-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  table {
    border-collapse: collapse;
    margin-top: 20px;
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }

  th {
    background-color: #f2f2f2;
  }

  td.selected {
    background-color: #FF4D49;
    color: #fff;
  }

  .clear-button {
    margin-top: 20px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: #f2f2f2;
    color: #333;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .clear-button:hover {
    background-color: #ddd;
  }
}