$black: #000000;
$white: #FFFFFF;
$error: #B00020;
$success: #72E128;
$market_place_theme: #666CFF;
$border_color: #4c4e64;
$border_color_light: rgba(76, 78, 100, 0.68);
$grey_light: #BDBDBD;
$comet: #636578;
$comet_light: rgba(76, 78, 100, 0.22);
$alto: #D9D9D9;
$waterloo: #868796;
$text_input_label: rgba(76, 78, 100, 0.87);