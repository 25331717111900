@import '@core/constants/color-constants.scss';

.social-media-box {
    height: 141px;
    width: 230px;
    border-radius: 10px;
    padding: 20px;
    margin-right: 20px;
    background-color: $white;
}

.job-detail-main-block {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
    overflow: hidden;
    .job-detail-block {
        .detail-left-block {
            border-radius: 10px;
            box-shadow: 0px 2px 10px $comet_light;
            overflow: hidden;
            margin-bottom: 20px;
        }
        .detail-img-overlay {
            padding: 12px;
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.3);
            width: 100%;
            justify-content: space-between;
          }
        .publish-type-img {
            height: 42px;
            width: 42px;
            border-radius: 100px;
            background: $white;
            padding: 8px;
            justify-content: center;
            align-items: center;
            display: flex;
        }
        .detail-title {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        .linear-progress{
            height: 6px;
            margin-top: 8px;
            border-radius: 5px;
        }
        .detail-right-block {
            display: flex;
            flex-direction: row;
            background: $white;
            border-radius: 10px;
            box-shadow: 0px 2px 10px $comet_light;
            padding: 20px;
            margin-bottom: 25px;

            .detail-brand-img {
                height: 70px;
                width: 100px;
                display: flex;
                align-items: center;
                background: $white;
                margin-right: 15px;
            }
            .brand_title {
                margin-bottom: 20px;
                color: $comet;
                align-items: center;
                display: flex;
            }
            .brand_desc {
                color: $border_color_light;
                align-items: center;
                display: flex;
            }
        }

        .post-type-block {
            background: $white;
            border-radius: 10px;
            box-shadow: 0px 2px 10px $comet_light;
            padding: 20px;
            margin-bottom: 25px;

            .earning-txt {
                color: rgba(76, 78, 100, 0.87);
                font-size: 18px;
                margin-right: 6px;
            }
        }

        .detail-dialog-title {
            font-size: 24px;
            font-weight: 500;
            color: rgba(76, 78, 100, 0.87);
            // padding-bottom: 0;
        }

        .jobmilestone-block {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 40px;

            .milestone-img {
                height: 40px;
                width: 40px;
            }
        }
    }
}

.details-dialog-subtitle {
    @media (max-width: '375px') {
        font-size: 12px !important;
        text-align: justify;
    }
    &.details-dialog-subtitle-new{
        display: list-item;
        padding-bottom: 0px;
        padding-left: 3px;
    }
}

.file-uploader-block {
    border-width: 1px;
    border-style: dashed;
    border-radius: 10px;
    border-color: $comet;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    padding-right: 10px;
  }
.continue-btn {
    width: 100%;
    background: $market_place_theme;
    font-size: 14;
    font-weight: 500;
  }

.submission-success-img {
height: 38px;
@media(max-width: '426px'){
    height: 26px;
}
}

.job-title-block {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    @media (max-width: '767px') {
        display: block;
        margin-bottom: 25px;
    }
}

.txt-back-to-overview {
    cursor: pointer;
    text-transform: uppercase;
    margin-left: 10px;
    color: #666CFF !important;
    @media (max-width: '767px') {
        margin-bottom: 10px !important;
    }
}

.upload-img {
    width: 48px;
    margin: 100px;
    margin-bottom: 80px;
}

.dialog_content {
    text-align: justify;
    max-width: 394px;
}

.creator-info-block {
    margin-top: 20px;
    padding: 20px;
    background-color: $white;
    box-shadow: 0px 2px 10px $comet_light;
    border-radius: 10px;

    .social-connection-img {
        height: 30px;
        width: 30px;
        margin-bottom: 10px;
    }
    .txt-edit {
        color: $market_place_theme;
        text-transform: uppercase;
        font-weight: 500;
        cursor: pointer;
      }
}

.profile-img-block {
    height: 120px;
    width: 120px;
    border-radius: 10px;
    background-color: $grey_light;
    margin-right: 20px;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    overflow: hidden;
}

.user-profile-block {
    height: 100%;
    width: 100%;
    object-fit: fill;
}

.upload-content-btn {
    margin-right: 5px;
    background: $market_place_theme;
    font-size: 14px;
    font-weight: 500;
}

.active-bullet {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: $success;
    margin-right: 6px;
}

.paypal-account-block {
    background-color: rgba(58, 53, 65, 0.08);
    border-radius: 16px;
    padding: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 50px;
    width: fit-content;
}

.paypal-acocunt-card {
    display: flex;
    color: rgba(76, 78, 100, 0.87);
    margin-right: 6px;
}

.linear-pogress-block {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    .progress-line { width: 100%; margin-right: 1px; }

}

.submission-status-block {
    padding: 4px;
    margin: 6px;
    border-radius: 26px;
    background-color: rgba(0, 0, 0, 0.6);
    // width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    .status-img {
        height: 35px;
        width: 35px;
        padding: 4px;
        border-radius: 50px;
        background-color: $black;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 3px;
    }
}

.my-submission-block {
    padding-left: 10px;
    height: 100%;
    overflow: hidden;
}

.earning-overview {
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .total-earning {
        padding: 20px;
        background-color: $white;
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        box-shadow: 0px 2px 10px rgba(76, 78, 100, 0.22);

        .earning-signs {
            height: 40px;
            width: 40px;
            margin-right: 16px;
        }

        .tooltip-icon {
            height: 18px;
            width: 18px;
            margin-right: 16px;
        }
      }
}

.job-list-carousel {
    padding-left: 10px;
}
