@import 'src/pages/marketplace/create-content/styles';
@import 'src/@core/constants/color-constants.scss';

.header-title {
    font-family: Inter;
    font-size: 24px !important;
    line-height: 32px;
    letter-spacing: 0.15px;
    vertical-align: top;
    color: rgba(76, 78, 100, 0.87);
    font-weight: 600 !important;
}

.header-text {
    font-family: Inter;
    font-size: 14px !important;
    line-height: 20px;
    letter-spacing: 0.15px;
    vertical-align: top;
    letter-spacing: 0.15px;
    color: rgba(76, 78, 100, 0.87);
    font-weight: 400 !important;
}

.header-text {
    font-family: Inter;
    font-size: 14px !important;
    line-height: 20px;
    letter-spacing: 0.15px;
    vertical-align: top;
    letter-spacing: 0.15px;
    color: rgba(76, 78, 100, 0.87);
    font-weight: 400 !important;
}

.bottom-text {
    font-family: Inter;
    font-size: 16px !important;
    line-height: 24px;
    letter-spacing: 0.15px;
    vertical-align: top;
    letter-spacing: 0.15px;
    color: rgba(76, 78, 100, 0.87);
    font-weight: 400 !important;
}

.dashboard-title {
    font-family: Inter;
    font-size: 20px !important;
    line-height: 24px;
    letter-spacing: 0.15px;
    vertical-align: top;
    color: rgba(76, 78, 100, 0.87);
    font-weight: 700 !important;
}

.layout-wrapper {
    background-color: #F7F7F9;
}

// .optionContainer .highlight
.optionContainer li:hover {
    background: #FF4D49 !important;
    color: $white;
}

.optionContainer .highlight {
    background: $white !important;
    color: rgba(76, 78, 100, 0.87);
}

.file-details-block {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 10px;
    height: 120px;
    width: 120px;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    margin-top: 20px;
    button {
        position: absolute;
        top: -10px;
        right: -10px;
        background: #ccc;
    }
}

.file-details-planner {
    border: 1px solid #ccc;
    border-radius: 10px;
    height: 120px;
    width: 120px;
    // justify-content: center;
    align-items: center;
    display: flex;

}

.file-details-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    button {
        position: absolute;
        top: -5px;
        right: 0px;
        background: #ccc;
    }
}

.file-preview-container {
    overflow: hidden;
    padding: 10px;
    width: 100%;
}

.market-place-block {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    align-items: flex-start;
    background: $white;
    box-shadow: 0px 2px 10px $comet_light;
    border-radius: 10px;
    cursor: pointer;

    .app-type-img {
        width: 70px;
        height: 70px;
    }
}

.marketplace-main-block {
    height: 100%;
    width: 100%;
    margin-top: 5px;
    padding: 0;
    flex-direction: row;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .creator-market-place-block {
        width: 297px;
        cursor: pointer;

        .market-place-title {
            color: $border_color;
            font-weight: 500;
            font-size: 20px;
            margin-bottom: 10px;
        }

        .index-caption {
            width: 298px;
            color: $waterloo;
        }

        .txt-or {
            color: $border_color;
            margin-top: 80px;
        }
    }
}

.job-list-container {
    display: flex;
    height: fit-content;
}

.job-list-cate-container {
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    margin-bottom: 35px;
}

.list-card-block {
    position: relative;
    background: $white;
    box-shadow: 0px 2px 10px $comet_light;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 25px;
    margin-bottom: 20px;

    .heading-block {
        position: relative;
        width: 100%;
        height: 162px;

        .detail-block {
            padding: 12px;
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.3);
            width: 100%;
            justify-content: space-between;

            .detail-left-icon {
                height: 42px;
                width: 42px;
                border-radius: 100px;
                background: $white;
                padding: 5px;
                justify-content: center;
                align-items: center;
                display: flex;
            }
        }
    }

    .social-detail-block {
        flex-direction: row;
        justify-content: space-between;
        display: flex;
        align-items: center;
        margin-top: 10px;
      }

    &.MuiListItem-root {
        width: 283px;
        flex-direction: column;
        align-items: flex-start;
    }

    .quote-feedback {
        position: relative;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        font-weight: 400;
        font-style: italic;
        color: $border_color_light;

        &::before{
            content: "\"";
        }

        &::after{
            content: "\"";
            display: block;
            position: absolute;
            bottom: 0;
            right: 2px;
        }
    }
}

.detail-left-icon {
    height: 42px;
    width: 42px;
    border-radius: 100px;
    background: $white;
    padding: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.list-card-image {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.list-card-title {
    color: $white;
    font-size: 20px;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-card-bid {
    color: #3C3C3C !important;
    font-weight: 700 !important;
}

.job-list-carousel {
    user-select: none;
    margin-bottom: 25px;
    padding-bottom: 10px;

    // li {
    //     max-width: 273px !important;
    // }

    .carousel-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 41px; width: 41px;
        border-radius: 50%;
        background: $market_place_theme;
        z-index: 1000;
        position: absolute;
    }

    .right {
        right: 20px;
    }

    .left {
        left: 20px;
    }

    &>.react-multiple-carousel__arrow {
        height: 67px;
        width: 67px;
        background: $market_place_theme;

        &:hover {
            background: $market_place_theme;
        }
    }
}

.job-detail-block {
    // background-color: $white;
    min-height: 100%;
}

.job-detail-image {
    width: 100%;
    height: 216px;
}

.job-detail-social-image {
    height: 52px;
    width: 52px;
}

.jm-timeline-sec {
    position: relative;
    background: $white;
    width: 98%;

    .jm-price-text {
        font-weight: 700;
        font-size: 12px;
    }

    .jm-views-text {
        font-weight: 400;
        font-size: 12px;
        width: max-content;
    }

    ol {
        &:before {
            content: "";
            width: 88%;
            border-radius: 12px;
            position: absolute;
            border-top: 8px solid #D9D9D9;
            bottom: 2px;
        }

        &.jm-timeline {
            margin: 100px 0;
            padding: 0;
            height: 1px;
            list-style: none;

            li {
                float: left;
                width: 25%;
                padding-top: 30px;
                padding-left: 0px;
                padding-bottom: 0px;
                position: relative;
                display: block;

                span {
                    position: absolute;
                    top: 0;
                    left: 50%;

                    &.jm-sp-top {
                        content: "";
                        width: 3px;
                        height: 40px;
                        background-image: linear-gradient(1800deg, transparent, transparent 50%, $white 50%, $white 100%), linear-gradient(180deg, #D1D1D1, #D1D1D1, #D1D1D1, #D1D1D1, #D1D1D1);
                        background-size: 6px 12px, 100% 3px;
                        border: none;
                        top: -50px;
                        left: 50%;
                    }

                    &.jm-sp-bot {
                        content: "";
                        width: 3px;
                        height: 39px;
                        background-image: linear-gradient(1800deg, transparent, transparent 50%, $white 50%, $white 100%), linear-gradient(180deg, #D1D1D1, #D1D1D1, #D1D1D1, #D1D1D1, #D1D1D1);
                        background-size: 6px 12px, 100% 3px;
                        border: none;
                        bottom: -45px;
                        left: 50%;
                    }
                }

                .content-handler-top {
                    position: absolute;
                    bottom: 0;
                    margin-bottom: 76px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    img {
                        display: table;
                        margin: 0 auto;
                    }
                }

                .content-handler-bot {
                    position: absolute;
                    margin-top: 10px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    img {
                        display: table;
                        margin: 0 auto;
                    }
                }

                p {
                    text-align: center;
                    margin: 0 auto;
                }

                .jm-top {
                    position: absolute;
                    bottom: 0;
                    margin-bottom: 100px;
                }

                .jm-bot {
                    position: absolute;
                    margin-top: 35px;
                }
            }
        }
    }
}

.create-job-block {
    .MuiStepConnector-root {
        &.Mui-disabled .MuiStepConnector-line {
            border-color: #ECEDFF !important;
        }

        bottom: 30
    }

    .platform-img {
        height: 20px;
        width: 20px;
        margin-right: 8px;
    }
}

.my-creator-info-block {
    height: 100%;
    display: flex;
    flex-direction: column;

    .info-button {
        height: 40px;
        font-size: 14px;
        font-weight: 500;
    }

    input[type=file] {
        display: none;
    }

    .brand-logo-img {
        height: 120px;
        width: 120px;
        border-radius: 10px;
        background-color: $grey_light;
        margin-right: 20px;
        margin-bottom: 10px;
        justify-content: center;
        align-items: center;
        display: flex;
        overflow: hidden;
    }

    .upload-logo-btn {
        margin-right: 5px;
        background: $market_place_theme;
        font-size: 14px;
        font-weight: 500;
    }

    .brand-detail-block {
        margin-top: 40px;
        padding: 20px;
        background-color: $white;
        box-shadow: 0px 2px 10px rgba(76, 78, 100, 0.22);
        border-radius: 10px;
      }
}

.custom-radio-btn {
    width: 100%;
    margin-bottom: 8px;
    margin-left: 16px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    cursor: pointer;

    .custom-radio-box {
        width: inherit;
        height: 100%;
        // border: 1px solid;
        border-radius: 8px;
        padding: 20px 20px 10px;
        display: flex;
        justify-content: end;
        align-items: center;
        text-align: center;
        flex-direction: column;
    }
}

.all-jobs {
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
    overflow: hidden;

    .select-filter {
        max-height: 45px;
        width: 100%;
        margin-top: 2px;
    }

    &.main-submission-block {
        padding-left: 10px;
        padding-right: 10px;
        height: 100%;
        overflow: hidden;
    }

    .MuiTabs-scrollButtons {
        display: none;
    }

    .MuiTabPanel-root {
        padding-top: 0;
        padding-left: 0;
    }
}
.filter-publish-type-img {
    height: 20px;
    width: 20px;
    margin-right: 7px;
}

.file-details-container-new-job {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    button {
        position: absolute;
        top: 6px;
        right: -13px;
        background: #ccc;
        z-index: 999;
    }
}

.image-wrapper{
    padding: 12px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    justify-content: space-between;
    &:hover{
        background: linear-gradient(183.61deg, rgba(0, 0, 0, 0.4) 2.97%, rgba(0, 0, 0, 0.33) 108.85%);
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        width: 100%;
        height: 100%;
    }
    @media (max-width: '767px') {
        display: block;
    }
}

.submission-image-wrapper {
    // padding: 10px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;

    justify-content: space-between;
    &:hover{
        background: linear-gradient(183.61deg, rgba(0, 0, 0, 0.4) 2.97%, rgba(0, 0, 0, 0.33) 108.85%);
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        width: 100%;
        height: 100%;
    }
}

.sync-flex-direction {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.sync-justify-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.sync-flex-direction-align {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.full-height-width {
    height: 100%;
    width: 100%;
}

.img {
    height: 100%;
    width: 100%;
}

.sync-align-center {
    display: flex;
    align-items: center;
}

.sync-align-justify-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-color-gradient {
    background-color: "linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #FF4D49;",
}

.sync-direction-column {
    display: flex;
    flex-direction: column;
}

.align-justify-item-center {
    display: flex;
    align-items: center;
    justify-items: center;
}

.input-width {
    width: 221px
}

.sync-justify-center {
    display: flex;
    justify-content: center;
}

.sync-justify-end {
    display: flex;
    justify-content: flex-end;
}

.sync-direction-row {
    display: 'flex';
    flex-direction: 'row';
}

.sync-flex-wrap {
    display: "flex";
    align-items: "center";
    flex-wrap: "wrap";
    justify-content: "space-between";
}

.sync-justify-between {
    display: "flex";
    align-items: "center";
    justify-content: "space-between"
}
.disc {
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: $border_color_light;
    margin-right: 10px;
}

.loader {
    min-height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.creator-marketplace-block-index {
    width: 297px;
    height: 130px;
    background: $alto;
    border-radius: 15px;
    margin-bottom: 28px;
}

.category_chip{
    margin-bottom: 5px;
    &.MuiChip-root {
        color: #636578;
        border-color: #636578;
        .MuiChip-label {
            font-weight: 700;
        }
    }
    &.MuiChip-filled {
        color: #FFFFFF;
        background: #636578;
        &:hover{
            color: #FFFFFF;
            background: #636578;
        }
    }

}

.no-data-found-block {
    height: 250px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}
.no-data-found-img { height: 45px; width: 45px; opacity: 0.4; }

.no-data-found-btn {
    margin: 0px;
    padding: 0px;
    text-transform: capitalize;
    margin-right: 3px;
}

.search-box {
    padding: 2px;
    justify-content: flex-end;
    display: flex;
}

.no-data-found-box {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 60px;
}

.billing-label {
    margin-bottom: 10px;
    color: rgba(76, 78, 100, 0.87);
}

.card-input {
    height: 56px;
    width: 100%;
    border: 1px solid rgba(76, 78, 100, 0.12);
    padding: 10px;
    border-radius: 8px;
    & :focus, & :active {
        border: 1px solid rgba(76, 78, 100, 0.12);
    }
}

.submission-left-block {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    .left-absolute-block {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 8px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .video-btn {
            height: 30px;
            width: 30px;
            display: flex;
            align-self: center;
        }
    }
}

.status-block {
    min-height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .resubmit-btn {
        border-width: 0;
        background: transparent;
        padding: 0;
        color: $market_place_theme;
        margin-bottom: 7px;
      }
}

.borderRadiusSpecific {
    background-color: $white;
    padding: 30px 30px 0;
    @media (max-width: '767px') {
        padding-left: 10px;
        padding-right: 10px;
        display: block !important;
      }

      &.box-border{
        padding-bottom: 25px;
        border-radius: 10px;
        box-shadow: 0px 2px 10px rgba(76, 78, 100, 0.22);
      }

      .job-category{
        margin-bottom: 30px;
        cursor: pointer;
        width: 300px;
        @media (max-width: '899px') {
            width: 100%;
        }
      }

      .job-budget{
        min-width: 120px;
        width: 20%;
        margin-bottom: 5px;
        @media (max-width: '899px') {
            width: 100%;
        }
      }

      .job-requirement{
        justify-content: unset;
        @media (max-width: '767px') {
            justify-content: center;
        }
      }

      .collect-video-post{
        display: flex;
        flex-basis: 290px;
        @media (max-width: '767px') {
            display: block;
            flex-basis: 290px;
        }
      }

      &.billing-details {
        background-color: white;
        box-shadow: 0px 2px 10px rgba(76, 78, 100, 0.22);
        border-radius: 10px;
        padding: 30px 40px;
      }

      .total-payment {
        margin-bottom: 20px;
        background: rgba(102, 108, 255, 0.05);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px;
        border-radius: 8px;
      }

      .box-payment-option {
        padding: 0.2rem;
        border-radius: 8px;
        border: 1px solid rgba(76, 78, 100, 0.12);
      }

      .swtich-btn {
        padding-left: 20px;
        justify-content: unset;
        @media (max-width: '767px') {
            justify-content: center;
        }
      }
}

.footer-button {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    align-items: center;
    margin-top: 5;
}
.showMore {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #666CFF !important;
}

.gradient-btn {
    color: #fff;
    background: linear-gradient(87.87deg, #FF4D49 0%, #666CFF 53.65%, #26C6F9 100%);
    &:hover{
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(87.87deg, #FF4D49 0%, #666CFF 53.65%, #26C6F9 100%);
    }
}

.MuiStepLabel-labelContainer span {
    @media (max-width: '767px') {
        font-size: 10px;
      }
}

.MuiStepper-alternativeLabel {
    @media (max-width: '767px') {
    margin-left: -23px;
}
}

.gradient-border-box{
    border: double 1px transparent;
    border-radius: 10px;
    background-origin: border-box;
    background-clip: content-box, border-box;
    background-image: linear-gradient(white, white), linear-gradient(87.87deg, #FF4D49 0%, #666CFF 53.65%, #26C6F9 100%);
}
.file-name {
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    width:100px;

}

.simple-border-box{
    border-radius: 10px;
    border: 1px solid rgba(76, 78, 100, 0.12)
}

.success-btn {
    &.MuiButtonBase-root {
        color: #72E128;
        border-color: #72E128;
        background: #fff;
        &.MuiButton-outlinedPrimary:hover{
            color: #72E128;
            border-color: #72E128;
            background: #fff;
        }
    }
}

.subscription-plan {
    border: 2px solid;
    // border-width: 1px;
    // border-style: solid;
    // // border-image:linear-gradient(87.87deg, #FF4D49 0%, #666CFF 53.65%, #26C6F9 100%) 0% 0% 100% 0%;
    // border-image:linear-gradient(87.87deg, #FF4D49 0%, #666CFF 53.65%, #26C6F9 100%) 1 1;
    // border-radius: 10px;
    // padding: 10px;
}

.full-withradius {
	position: relative;
	background: #fff;

	/*The background extends to the outside edge of the padding. No background is drawn beneath the border.*/
	background-clip: padding-box;

	border: solid 8px transparent;
	border-radius: 0.8rem;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
		margin: -8px; /* same as border width */
		border-radius: inherit; /* inherit container box's radius */
		background: linear-gradient(to left, turquoise, greenyellow);
	}
}

.common-menu-active-link{
    background: linear-gradient(87.87deg, #FF4D49 0%, #666CFF 53.65%, #26C6F9 100%);
}

.gradient-linear-progress{
    &.MuiLinearProgress-root.MuiLinearProgress-colorPrimary{
        height: 2px;
        background-color: transparent;
    }
}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.loading-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  animation: spin 1s linear infinite;
}

.loading-message {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.drawer-loader-backdrop {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: unset;
    right: 0;
    height: 100%;
    // width: 60%;
    background-color: rgba(255, 255, 255, 0.8);
}



.upload__status{
	position: fixed;
	bottom:0px;
	right:20px;
	z-index: 5;
	background: #FFFFFF;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15), inset 0px 1px 0px #F5F7FA;
	border-radius: 4px;
	border-top-left-radius:5px;
	border-top-right-radius: 5px;
	max-width:315px;
	overflow:hidden;
	& .content__upload{
		max-height: 300px;
		overflow-y: scroll;
		&>.elem__upload{
			position: relative;
			&.uploading__now{
				&:hover{
					& .stop__download{
						display: block;
					}
					& .upload__size>span{
						display: none;
					}
				}
				& .stop__download{
					position: absolute;
					top:20px;
					cursor:pointer;
					right: 20px;
					display: none;
					transition:.4s ease all;
					&:hover{
						opacity:.7;
					}
				}
			}
			&.uploaded__cancelled{
				&>.upload__info{
					&>.top__upload{
						&>.retry__download{
							&>a{
								text-decoration:none;
								transition:.4s ease all;
								color:#3c85ee;
								font-size:13px;
								font-weight:bold;
								&:hover{
									opacity:.7;
								}
							}
						}
						margin-bottom:5px;
					}
				}
			}
			padding:20px;
			display: flex;
			justify-content:space-between;
			align-items:flex-start;
			transition:.4s ease all;
			&:hover{
				background: #F6F5FD;
			}
			&>.upload__info{
				width:100%;
				&>.bottom__upload{
					& .failed__info{
						&>span{
							display: inline-flex;
							color:#BF0711;
							font-size:12px;
							font-weight:400;
						}
					}
					&>.progress__upload{
						width:100%;
						background: #E0DCF3;
						border-radius: 1.5px;
						height:3px;
						position: relative;
						& .active__progress{
							height:3px;
							background: #3c85ee;
							border-radius: 1.5px;
						}
					}
				}
				&>.top__upload{
					display: flex;
					justify-content:space-between;
					align-items:center;
					margin-bottom:15px;
					&>.upload__text{
						padding-right:30px;
						& p{
							// display: inline-flex;
							// word-break:break-all;
							// max-width:200px;
							color: #0E1C71;
							font-size:15px;
							line-height:18px;
							font-weight:500;
							max-width: 160px;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
					}
					&>.upload__size{
						&>span{
							color: #637381;
							font-size:13px;
							line-height:16px;
							font-weight:400;
						}
					}
					& p{
						margin:0px;
					}
				}
			}
			&>.upload__elem--status{
				display: inline-flex;
				margin-right:12px;
				&>img{
					min-width:26px;
					max-width:26px;
				}
			}
		}
	}
	& .head__upload{
		padding:20px 15px;
		background: #3c85ee;
		min-width:315px;
		border-radius: 0px 0px 3px 3px;
		display: flex;
		align-items:center;
		position: relative;
		& .hide__upload{
			position: absolute;
			right:15px;
			display: inline-flex;
			&>a{
				display: inline-flex;
				align-items:center;
				justify-content:center;
				padding:5px;
				transition:.4s ease all;
				&:hover{
					opacity:.7;
				}
			}
		}
		&>p{
			color: #FFFFFF;
			margin:0px;
			font-size:15px;
			line-height:19px;
		}
	}
}


@media (max-width:1200px){
    .upload__status {
		&>.head__upload{
			padding:15px 12px;
		}
		& .content__upload{
			&>.elem__upload{
				padding:12px;
			}
		}
	}
}

@media (max-width:640px){
    .upload__status{
		right:0px;
		width:100%;
		border-radius:0px;
		max-width:100%;
		position: fixed;
		bottom:0px;
		&>.head__upload{
			padding:10px;
		}
		& .content__upload{
			// display: none;
			max-height:120px;
			overflow-y: scroll;
			&>.elem__upload {
				padding:10px;
			}
		}
	}
}

.review_media_common_container {
    display: flex;
    margin: 14px;
    flex-direction: column;

    .review_label {
        font-weight: 500;
        color: rgba(76, 78, 100, 0.87);
        margin: 10px;
        flex-basis: 0;
        flex-grow: 1;
    }

    .review_value {
        margin: 10px;
        flex-basis: 0;
        flex-grow: 1.5
    }
}